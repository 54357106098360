<template v-slot:body>
    <b-overlay :show="loading">
        <b-row>
        <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
              <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="org_id"
                >
                  <template v-slot:label>
                    {{ $t('orgProfile.org_name')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="releaseOrder.org_id"
                    :options="orgProfileList"
                    id="org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    disabled
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="designation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('elearning_tim.1st_designation') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="releaseOrder.designation_id"
                :options="designationList"
                @input="getUser(releaseOrder.designation_id)"
                id="designation_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Officer Name" vid="receiver_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="receiver_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('elearning_tim.1st_officer') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="releaseOrder.receiver_id"
                :options="userList"
                id="receiver_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Course Designation (En)" vid="course_designation">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="course_designation"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                  {{$t('elearning_tim.1st_course_designation')}} {{$t('globalTrans.en')}}
                  </template>
                  <b-form-input
                  id="course_designation"
                  v-model="releaseOrder.course_designation"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Course Designation (Bn)" vid="course_designation_bn">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="course_designation_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_tim.1st_course_designation')}} {{$t('globalTrans.bn')}}
                </template>
                <b-form-input
                  id="course_designation_bn"
                  v-model="releaseOrder.course_designation_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Signature" vid="signature" :rules="editId === 1?'':'required'">
                <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="attachment"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{$t('elearning_tim.1st_signature')}}
                </template>
                <b-form-file
                  id="signature"
                  v-on:change="onLeftAttachmentChange"
                  v-model="releaseOrder.signature"
                  accept="image/*"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-file>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Designation" vid="second_designation_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="second_designation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('elearning_tim.2nd_designation') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="releaseOrder.second_designation_id"
                :options="designationList"
                @input="getUserSecond(releaseOrder.second_designation_id)"
                id="second_designation_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Officer Name" vid="second_receiver_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="second_receiver_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('elearning_tim.2nd_officer') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="releaseOrder.second_receiver_id"
                :options="SecondUserList"
                id="second_receiver_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Course Designation (En)" vid="second_course_designation">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="second_course_designation"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                  {{$t('elearning_tim.2nd_course_designation')}} {{$t('globalTrans.en')}}
                  </template>
                  <b-form-input
                  id="second_course_designation"
                  v-model="releaseOrder.second_course_designation"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Course Designation (Bn)" vid="second_course_designation_bn">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="second_course_designation_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_tim.2nd_course_designation')}} {{$t('globalTrans.bn')}}
                </template>
                <b-form-input
                  id="second_course_designation_bn"
                  v-model="releaseOrder.second_course_designation_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Signature" vid="second_signature" :rules="editId === 1?'':'required'">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="attachment"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
              {{$t('elearning_tim.2nd_signature')}}
              </template>
              <b-form-file
                id="second_signature"
                v-on:change="onTrainerAttachmentChange"
                v-model="releaseOrder.second_signature"
                accept="image/*"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
            <div class="row">
            <div class="col-sm-3"></div>
            <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                &nbsp;
                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
            </div>
            </div>
            </b-form>
            </ValidationObserver>
        </b-col>
        </b-row>
    </b-overlay>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { certificateDetailsStore, certificateDetailsUpdate, certificateDetail } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.item) {
      this.getEditingData()
      if (this.releaseOrder.org_id) {
        this.getDesignation(this.releaseOrder.org_id)
      }
    }
  },
  data () {
    return {
      editId: 0,
      loading: false,
      isOfficeAdmin: false,
      userLoading: false,
      defaultValue: '',
      saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
      releaseOrder: {
        org_id: this.item.org_id,
        designation_id: 0,
        second_designation_id: 0,
        receiver_id: 0,
        second_receiver_id: 0,
        course_designation: '',
        course_designation_bn: '',
        second_course_designation: '',
        second_course_designation_bn: '',
        signature: [],
        second_signature: [],
        circular_memo_no: this.item.circular_memo_no,
        batch_id: this.item.batch_id
      },
      seedNameList: [],
      userList: [],
      SecondUserList: [],
      designationList: []
    }
  },
  computed: {
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
          return true
      } else {
        return false
      }
    },
    orgProfileList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeUserList(newVal)
      }
    }
    // 'releaseOrder.org_id': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.getDesignation(newVal)
    //   }
    // }
  },
  mounted () {
  },
  methods: {
    getDesignation (orgId) {
      const designation = this.$store.state.CommonService.commonObj.componentDesignationList.filter(item => item.org_id === orgId)
      this.designationList = designation.sort(function (a, b) {
        return a.grade_id > b.grade_id ? 1 : -1
      })
    },
    getUser (designationId) {
      this.getUserList(designationId)
    },
    getUserSecond (designationId) {
      this.getSecondUserList(designationId)
    },
    getUserList (designationId) {
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, `user/users-by-designation-id/${designationId}`).then(response => {
        const userList = response.filter(obj => obj.value !== this.$store.state.Auth.authUser.user_id).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.value,
            text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          })
        })
        this.userList = userList
        this.userLoading = false
        return userList
      })
    },
    getSecondUserList (designationId) {
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, `user/users-by-designation-id/${designationId}`).then(response => {
        const SecondUserList = response.filter(obj => obj.value !== this.$store.state.Auth.authUser.user_id).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.value,
            text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn
          })
        })
        this.SecondUserList = SecondUserList
        this.userLoading = false
        return SecondUserList
      })
    },
    localizeUserList (locale) {
      const tmp = this.userList.map(item => {
        return Object.assign({}, item, {
          text: locale === 'en' ? item.text_en : item.text_bn
        })
      })
      this.userList = tmp
      const tmp1 = this.getSecondUserList.map(item => {
        return Object.assign({}, item, {
          text: locale === 'en' ? item.text_en : item.text_bn
        })
      })
      this.getSecondUserList = tmp1
    },
    // getEditingData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // },
    async getEditingData () {
      this.loading = true
      const circularMemoNo = {
        circular_memo_no: this.item.circular_memo_no,
        batch_id: this.item.batch_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetail, circularMemoNo)
      if (!result.success) {
        this.loading = false
        // this.releaseOrder = []
      } else {
        this.editId = 1
        this.releaseOrder.circular_memo_no = result.data.circular_memo_no
        // this.releaseOrder.org_id = result.data.org_id
        this.releaseOrder.designation_id = result.data.designation_id
        this.releaseOrder.second_designation_id = result.data.second_designation_id
        this.releaseOrder.receiver_id = result.data.receiver_id
        this.releaseOrder.second_receiver_id = result.data.second_receiver_id
        this.releaseOrder.course_designation = result.data.course_designation
        this.releaseOrder.course_designation_bn = result.data.course_designation_bn
        this.releaseOrder.second_course_designation = result.data.second_course_designation
        this.releaseOrder.second_course_designation_bn = result.data.second_course_designation_bn
      }
      this.loading = false
    },
    onLeftAttachmentChange (e) {
      this.signature = e.target.files[0]
    },
    onTrainerAttachmentChange (e) {
      this.second_signature = e.target.files[0]
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.releaseOrder).map(key => {
        if (key === 'signature') {
          formData.append(key, this.signature)
        } else if (key === 'second_signature') {
            formData.append(key, this.second_signature)
        } else {
          formData.append(key, this.releaseOrder[key])
        }
      })

      if (this.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${certificateDetailsUpdate}/${this.id}`, this.releaseOrder)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, certificateDetailsStore, formData, config)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
